import { useState } from 'react'

import { CreateBusiness, UpdateBusiness } from '~/api'

import { Save } from 'lucide-react'
import { ImageUploader } from '~/components/Image/ImageUploader'
import { Modal } from '~/components/Modal/Modal'
import { BusinessCategory } from '~/constants/business'
import $posthog from '~/lib/posthog'
import { BusinessI, CurrencyCode } from '~/types'
import { getCurrencyList, getCurrentBusiness } from '~/utils'
import { useGlobal } from '../Common/GlobalDataProvider'
import { useLanguage } from '../Language/LanguageProvider'
import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import { Select, SelectContent, SelectItem, SelectSeparator, SelectTrigger, SelectValue } from '../ui/select'

interface PopupBusinessFormI {
    show: boolean
    data?: BusinessI
    onSave: (value: BusinessI) => void
    onClose: () => void
}

export const PopupBusinessForm = ({ show, data, onSave, onClose }: PopupBusinessFormI) => {
    const isEdit = !!data
    const { toastProps, setToastProps } = useGlobal()
    const [loading, setLoading] = useState(false)
    const { t, locale } = useLanguage()
    const [field, setField] = useState<BusinessI>(() => {
        if (isEdit) {
            return data
        }

        return {
            access: [],
            businessId: '',
            role: 'admin',
            category: '',
            imageUrl: '',
            name: '',
            currencyCode: CurrencyCode.IDR
        }
    })

    const submit = async () => {
        if (loading) {
            return
        }

        setLoading(true)

        const { data: business, error } = isEdit
            ? await UpdateBusiness(getCurrentBusiness(), {
                  category: field.category,
                  imageUrl: field.imageUrl,
                  name: field.name,
                  currencyCode: field.currencyCode || CurrencyCode.IDR
              })
            : await CreateBusiness({
                  category: field.category,
                  imageUrl: field.imageUrl,
                  name: field.name,
                  currencyCode: field.currencyCode || CurrencyCode.IDR
              })

        if (!!error) {
            setLoading(false)
            setToastProps({
                ...toastProps,
                message: error?.message || t('common.request_error'),
                type: 'error',
                open: true,
                wrapper: 'right-hand-side'
            })

            return
        }

        setToastProps({
            ...toastProps,
            message: t('common.message.successfully_saved'),
            type: 'success',
            open: true,
            wrapper: 'right-hand-side'
        })

        setLoading(false)

        if (isEdit) {
            onSave(data)
            const eventProps = {
                business_name: field.name,
                business_category: field.category,
                business_logo: field.imageUrl.length ? 'yes' : 'no',
                business_id: data.businessId
            }
            $posthog.capture('business_create_submit', eventProps)

            return
        }

        const _business = business as BusinessI
        onSave(_business)
        const eventProps = {
            business_name: field.name,
            business_category: field.category,
            business_logo: field.imageUrl.length ? 'yes' : 'no',
            business_id: _business.businessId
        }
        $posthog.capture('business_create_submit', eventProps)
    }

    return (
        <Modal
            size="sm"
            show={show}
            title={isEdit ? t('business.edit_business.title') : t('business.add_business.title')}
            close
            onClose={onClose}
            action={
                <Button disabled={loading} onClick={submit}>
                    <Save size={16} className="mr-2"></Save>
                    {t('common.button.submit')}
                </Button>
            }>
            <div className="grid grid-cols-1 gap-4 w-full max-w-sm mb-6">
                <ImageUploader
                    image={field.imageUrl}
                    setImage={(value) =>
                        setField({
                            ...field,
                            imageUrl: value
                        })
                    }></ImageUploader>
                <div className="grid grid-cols-1 gap-2">
                    <Label htmlFor="business-name">{t('business.add_business.name')}</Label>
                    <Input
                        id="business-name"
                        placeholder={t('business.add_business.name_placeholder')}
                        value={field.name}
                        onInput={(value) =>
                            setField({
                                ...field,
                                name: value.currentTarget.value
                            })
                        }></Input>
                </div>
                <div className="grid grid-cols-1 gap-2">
                    <Label htmlFor="business-category">{t('business.add_business.category')}</Label>
                    <Select
                        value={field.category}
                        onValueChange={(value) =>
                            setField({
                                ...field,
                                category: value
                            })
                        }>
                        <SelectTrigger id="business-category">
                            <SelectValue placeholder={t('business.add_business.category_placeholder')} />
                        </SelectTrigger>
                        <SelectContent>
                            {BusinessCategory.map((item) => (
                                <SelectItem key={item.key} value={item.key}>
                                    {item[locale]}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className="grid grid-cols-1 gap-2">
                    <Label htmlFor="business-currency">{t('business.add_business.currency')}</Label>
                    <Select
                        value={field.currencyCode}
                        onValueChange={(value) =>
                            setField({
                                ...field,
                                currencyCode: value as CurrencyCode
                            })
                        }>
                        <SelectTrigger id="business-currency">
                            <SelectValue placeholder={t('business.add_business.currency_placeholder')} />
                        </SelectTrigger>
                        <SelectContent>
                            <div className="flex items-center p-2 sticky -top-2 bg-white z-50">
                                <Input
                                    placeholder={t('business.add_business.currency')}
                                    className="h-8"
                                    onChange={(e) => {
                                        const input = e.target.value.toLowerCase()
                                        const items = document.querySelectorAll('[data-currency-item]')
                                        items.forEach((item) => {
                                            const text = item.textContent?.toLowerCase() || ''
                                            item.classList.toggle('hidden', !text.includes(input))
                                        })
                                    }}
                                />
                            </div>
                            <SelectSeparator></SelectSeparator>
                            {getCurrencyList().map((item) => (
                                <SelectItem key={item.code} value={item.code} data-currency-item>
                                    {item.emoji} <strong>{item.name}</strong> - {item.symbol}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>
        </Modal>
    )
}
